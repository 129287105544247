/* body {
  margin: 0px;
  padding: 0px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} 

 */



 /* Base Styles */
body {
  margin: 1vw;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif, Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px; /* Set base font size */
  line-height: 1.6; /* Improve readability */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Responsive Typography */
h1, h2, h3, h4, h5, h6 {
  line-height: 1.2;
  margin: 0.5em 0;
}

p {
  margin: 1em 0;
}

/* Responsive Layout */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Mobile-Friendly Media Queries */
@media (max-width: 768px) {
  body {
    font-size: 14px; /* Adjust base font size for smaller screens */
  }

  .container {
    padding: 0 15px; /* Reduce container padding on mobile */
  }

  h1 {
    font-size: 1.8rem; /* Adjust heading sizes for smaller screens */
  }

  p {
    font-size: 1rem; /* Adjust paragraph font size */
  }
}

@media (max-width: 480px) {
  body {
    font-size: 12px; /* Further adjust base font size for very small screens */
  }

  h1 {
    font-size: 1.6rem; /* Scale down headings further */
  }

  .container {
    padding: 0 10px; /* Further reduce container padding */
  }
}
