.confirmation-container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .confirmation-title {
    text-align: center;
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .track-name {
    text-align: center;
    font-size: 1.5em;
    margin-bottom: 20px;
  }
  
  .track-details, .bet-details, .race-horse-details {
    margin-bottom: 20px;
    padding: 15px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
  
  .track-details p, .bet-details p, .race-horse-details p {
    margin: 5px 0;
  }
  
  .race-horse-details {
    margin-top: 10px;
  }
  
  h3 {
    margin-bottom: 10px;
    color: #333;
  }
  
  h4 {
    margin-top: 0;
    color: #666;
  }
  
  .back-button {
    display: block;
    width: 100px;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
  }
  
  .back-button:hover {
    background-color: #0056b3;
  }
  