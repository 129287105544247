.program-card-wrapper {
  width: 98%;
  margin-left: 1vw;
  margin-top: 2vw;
  position: relative;
}

.button-container {
  display: flex;
  align-items: left;
  margin-top: 3vh;
  margin-right: 1vw;
  white-space: nowrap;
  overflow: hidden;
  width: 48%;
}

#reset {
}

.letters-wrapper {
  width: 100%;
  text-align: left;
}

.programcard-letters {
  display: inline-block;
  text-align: left;
  justify-content: space-between;
}

#letter-space {
  font-size: 12px;
  min-width: 0.30%;
  margin-left: 1px;
  margin-right: 1px;
}

#letter-space + #letter-space {
  text-align: center;
}

.tracks-location-title {
  margin-top: 3vh; /* Updated value */
  margin-bottom: 1%;
  text-align: left;
  font-weight: bold;
  font-size: x-large;
}

.clock-align {
  margin-top: 4vh;
  margin-right: 6%;
}

#tracks-shown {
  margin-top: -6vh; /* Updated value */
  max-height: 14px;
  margin-left: 4%;
  font-size: x-large;
  margin-bottom: 2%;
}

.number {
  max-width: 8%;
  max-height: 4%;
  margin-left: 1.5%;
  margin-right: 1%;
  margin-top: 8%;
  text-align: center;
  font-size: x-large;
}

.card-wrapper {
  margin-top: 3.5%;
  padding: 1%;
  border-style: solid;
  border-color: rgb(56, 53, 53);
  border-width: 1px;
  width: 32%;
}

.track-name-close-button {
  margin-top: 3vh; /* Updated value */
  width: auto; /* Adjust the width as needed */
  font-size: larger;
  text-align: right;
}

.daily-races {
  margin-top: 1vh; /* Updated value */
  width: auto; /* Adjust the width as needed */
  height: 40px;
  z-index: 2;
  background-color: green;
  transition: backgroundColor 0.3;
  cursor: pointer;
}

.track-close-button {
  font-size: large;
  margin-left: 2%;
  margin-right: 2%;
}

.horse-image-container {
  display: flex;
  justify-content: flex-end;
  width: 50%;
  float: right;
  margin-right: 2px;
}

.programcard-figure {
  margin-left: 30%;
  max-width: 44%;
  margin-top: -1%;
}

.tracks {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  max-width: 30%;
  margin-left: 3%;
  margin-right: 64%;
  margin-top: 3vh; /* Adjust the value as needed */
}


.track-name-title {
  font-size: large;
  text-align: left;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  width: 100%;
  padding-left: 2%;
}

.track-name-container {
  max-width: 94%;
}
