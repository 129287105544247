/* .race-card-wrapper{
  margin: 1%;
  margin-top: .25%;
  max-width: 98vw;
} */

/* .navbar{
position: fixed;
margin-top: -2vh;
margin-left: 0;
margin-bottom: 2vh;
width: 100% ;
} */


.navbar {
  position: fixed;
  top: 0;             /* Stick to the top */
  left: 0;            /* Align to the left */
  right: 0;           /* Stretch to the right */
  bottom: 20;
  z-index: 999;       /* Keep on top of other content */
}


.navbar-wrapper {
  position: sticky;
  top: 10;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 46px;
  background-color: #c3cbc6;
  margin-top: -1vh;
  margin-bottom: 1vh;
  font-style: 'normal';
  
}

.menu-list {
  position: relative;
  list-style: none;
  }

.menu-list > .dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  padding: 0;
  margin: 0;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  list-style: none;
}

.menu-list:hover > .dropdown,
.dropdown:hover {
  display: block;
}

.dropdown > li > a {
  display: block;
  padding: 10px 20px;
  color: #333;
  text-decoration: none;
}

.dropdown > li > a:hover {
  background-color: #f0f0f0;
}

.active {
  font-weight: bold;
}
