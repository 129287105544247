

.dataTable-div-wrapper{
margin-top: 3vh;
margin-left: 10px;
}

/* Style for the column selector dropdown */
#selectColumns {
  padding: 10px;
  font-size: 16px;
  border: none;
  border-bottom: 2px solid #ccc;
  background-color: #f9f9f9;
  margin-left: 25%;
  width: 40%;
  height: 240px;
  margin-bottom: 20px;
  vertical-align: text-top;
  }

/* Style for the table */
.dataTable {
  border-collapse: collapse;
  width: 98%;
  margin-left: 1%;
  margin-bottom: 20px;
}

/* Style for the table headers */
th {
  background-color: #f2f2f2;
  color: #444;
  padding: 12px;
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  border-bottom: 2px solid #ccc;
}

/* Style for the table data */
td {
  border-bottom: 1px solid #ddd;
  padding: 12px;
  text-align: left;
  font-size: 16px;
  color: #444;
}

/* Style for the entire component */
dataTable-div {
  max-width: 98%;
  margin: 0 auto;
  padding: 20px;
  background-color: #fafdf8;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

/* //////////////////////////////////////////////////////////////////////////// */

/* .container {
    max-width: 98%;
    margin: 0 auto;
    padding: 0 15px;
  }
  
  h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    border-collapse: collapse;
  }
  
  .table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
  }
  
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
  }
  
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
  }
  
  .table tbody + tbody {
    border-top: 2px solid #dee2e6;
  }
  
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0,0,0,.05);
  }
  
  .table-hover tbody tr:hover {
    background-color: rgba(0,0,0,.075);
  }
  
  .table-primary,
  .table-primary > th,
  .table-primary > td {
    background-color: #c6e0f5;
  }
  
  .table-hover .table-primary:hover {
    background-color: #b0d4f1;
  }
  
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #b0d4f1;
    }
   */