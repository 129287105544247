.clock-container {
  font-weight: bold;
  margin-top: 4vh;
  margin-left: 2vw;
  margin-bottom: 4%;
  width: 94vw;
}

#time {
  text-align: left;
  margin-top: 1%;
  margin-bottom: 1%;
}

#post-time {
  text-align: left;
  margin-top: 1%;
  margin-bottom: 1%;
}

#race-begins {
  text-align: left;
  margin-top: 1%;
}
