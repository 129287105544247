
.earnings-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .earnings-selection-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .earnings-select {
    margin-bottom: 10px;
    font-size: 1rem;
    padding: 5px 10px;
    border-radius: 5px;
    border: 2px solid #ccc;
    background-color: #fff;
    color: #333;
  }
  
  .earnings-details-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    padding: 20px;
    border-radius: 5px;
    border: 2px solid #ccc;
    background-color: #f1f1f1;
    color: #333;
  }
  
  .earnings-details-container h3 {
    margin-bottom: 10px;
  }
  
  .earnings-details-container p {
    margin: 5px 0;
  }
  


/* Earnings.css */
/* 
.container {
    max-width: 800px;
    margin: auto;
    }
    
    h2 {
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 2rem;
    }
    
    .row {
    display: flex;
    flex-wrap: wrap;
    margin: 2rem 0;
    }
    
    .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 1rem;
    margin-bottom: 2rem;
    }
    
    .col-sm-6 p {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    }
    
    .col-sm-6 p strong {
    font-weight: bold;
    margin-right: 0.5rem;
    }
    
    p.loading {
    font-size: 1.2rem;
    text-align: center;
    }
    
    
    
     */