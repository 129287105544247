
 .current-races {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1.3vw;
  max-width: 70vw;
  margin: 0 auto;
  align-items: center;
}

.television-screen {
  width: 100%; 
  height: 0;
  padding-bottom: 108%; 
  position: relative;
  border: 2px solid #000;
  overflow: hidden;
  box-shadow: 3px 3px 6px 2px;
}

.television-screen img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
