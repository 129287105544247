.race-screens-container {
  position: relative;
}

.leaderboard-overlay {
  position: absolute;
  top: 10;
  left: 0;
  z-index: 99;
  /* Add additional positioning styles as needed */
}



.race-screen-race-track {
  overflow-x: auto;
  margin-top: 4vh;
  scroll-snap-type: x mandatory;
}

.follow {
  /* scroll-snap-align: nearest ; */
  /* none, start, center, end, nearest  */
}

.start-gate{
  position: absolute;
  /* margin-top: 5.5vh; */
  top: -2px;
  left: -.2vw;
  width: 100.4vw;
  height: 100vh;
  transition: opacity 1s ease-out;
  z-index: +999;
}

.hidden {
  display: none;
}

.on-top {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999; 
}
.race-screen-horse-container {
  animation-name: horseAnimation;
  top: 0;
  left: 0;
}

.track-left {
  position: absolute;
  top: -1px;
  left: -.2vw;
  width: 100.4vw;
  height: 100vh;
  transition: opacity 1s ease-out;
}

.track-right {
  position: absolute;
  top: -1px;
  right: 0;
  margin-left: 50vw;
  width: 100.4vw;
  height: 100vh;
  opacity: 0;
  transition: opacity 1s ease-in;
}

.track-right.show {
  opacity: 1;
}

.fade-out {
  opacity: 1;
} 

.fade-in {
  opacity: 1;
}

.horse {
  position: absolute;
  height: auto;
  animation-name: horse-Animation;
  animation-iteration-count: 1 ;
  animation-fill-mode: forwards; 
  scroll-snap-align: end;
  /* transform: scale(0.75); */
}

@keyframes horse-Animation {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(calc(71vw + 50vw));
  }
}


.horse.follow {
  margin-left: -auto;
  animation-play-state: running;
}
