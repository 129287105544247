.live-stream-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
  }
  
  video {
    margin-bottom: 20px;
  }
  
  .horse-positions-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
 
  h1 {
    font-size: 3rem;
    margin-bottom: 20px;
  }
  
  p {
    font-size: 1.2rem;
    line-height: 1.5;
    margin-bottom: 10px;
  }
  
  button {
    background-color: #008CBA;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1.2rem;
    cursor: pointer;
    margin-top: 10px;
  }
  
  button:hover {
    background-color: #0077b3;
  }
  
  /* form div {
    margin-bottom: 10px;
  } */
  
  label {
    display: block;
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
  
  input[type="text"],
  input[type="password"] {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    font-size: 1.2rem;
    width: 100%;
  }
  
  input[type="text"]:focus,
  input[type="password"]:focus {
    outline: none;
    border-color: #008CBA;
  }
/*   
  .login-form,
  .registration-form {
    width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f2f2f2;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  } */
  
  .error {
    color: red;
    margin-bottom: 10px;
  }
  