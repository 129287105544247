/* Hamburger Button */
.hamburger-wrapper {
  position: relative;
top: 1vh;
}

.hamburger-menu-toggle {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  position: relative;
  z-index: 1;
}

.hamburger-line {
  width: 30px;
  height: 2px;
  background-color: #000;
  margin-bottom: 5px;
  transition: transform 0.3s ease;
}

.hamburger-menu-toggle.open .hamburger-line:first-child {
  transform: translateY(6px) rotate(45deg);
}

.hamburger-menu-toggle.open .hamburger-line:nth-child(2) {
  opacity: 0;
}

.hamburger-menu-toggle.open .hamburger-line:last-child {
  transform: translateY(-6px) rotate(-45deg);
}

/* Hamburger Menu */
.hamburger-menu {
  list-style-type: none;
  margin: 0;
  margin-top: 48vh;
  padding: 0;
  background-color: white;
  display: none;
}

.hamburger-menu.open {
  display: block;
}

.hamburger-list {
  padding: 10px;
  display: none;
}

.hamburger-menu.open .hamburger-list {
  display: block;
}

.hamburger-list a {
  text-decoration: none;
  color: #000;
}

.hamburger-list a:hover {
  color: #555;
}

/* Hamburger Dropdown Menu */
.hamburger-dropdown {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: none;
  background-color: #f5f5f5;
  max-height: 200px; /* Adjust this value as needed */
  overflow-y: auto;
}

.hamburger-dropdown.open {
  display: block;
}

.hamburger-dropdown .hamburger-list {
  padding: 8px 20px;
}

.hamburger-dropdown .hamburger-list:hover {
  background-color: #ebebeb;
}

.active {
  font-weight: bold;
}
